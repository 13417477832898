import { graphql } from "gatsby";
import React from "react";
import { ProductPageContents } from "../components/ProductPageContents";

const ProductPage = (props: {
    data: GatsbyTypes.productDataQuery;
    pageContext: { appId: string };
}) => {
    return (
        <>
            <ProductPageContents
                sanityData={props.data.allSanityProduct}
                appId={props.pageContext.appId}
            />
        </>
    );
};

export default ProductPage;

export const pageQuery = graphql`
    query productData {
        allSanityProduct {
            products: nodes {
                title
                atlassianProductId
                icon {
                    asset {
                        gatsbyImageData(placeholder: NONE)
                    }
                }
                productPage {
                    id
                    title
                    subtitle
                    slug {
                        current
                    }
                    tagLinks {
                        url
                        text
                    }
                    explainers {
                        imagePosition
                        heading
                        description
                        graphic {
                            imageType
                            image {
                                asset {
                                    gatsbyImageData(placeholder: NONE)
                                    _id
                                }
                            }
                            lottieFile {
                                asset {
                                    url
                                }
                            }
                            video {
                                mp4 {
                                    asset {
                                        url
                                    }
                                }
                                webm {
                                    asset {
                                        url
                                    }
                                }
                            }
                        }
                        ctaButton {
                            title
                            linkUrl
                            variation
                        }
                    }
                    featuredArticles {
                        id
                        description
                        featuredImage {
                            img {
                                asset {
                                    gatsbyImageData(placeholder: NONE)
                                }
                            }
                        }
                        slug {
                            current
                        }
                        title
                        category {
                            name
                        }
                    }
                    featuredArticlesTitle
                    featuredTestimonials {
                        name
                        company
                        quote
                        profile {
                            alt
                            link
                            padding
                            borderRadius
                            img {
                                asset {
                                    gatsbyImageData(placeholder: NONE)
                                }
                            }
                        }
                        logo {
                            asset {
                                gatsbyImageData(placeholder: NONE)
                            }
                        }
                    }
                    demoUrl
                    topButtonUrl
                    topButtonLabel
                    bottomButtonUrl
                    bottomButtonLabel
                    productBarLinks: mainLinks {
                        icon {
                            asset {
                                gatsbyImageData(placeholder: NONE)
                            }
                        }
                        text
                        url
                    }
                    _rawBody
                }
            }
        }
    }
`;
