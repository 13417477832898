import React, { useContext, useState } from "react";
import styled, { ThemeContext } from "styled-components";
import { Testimonial, TestimonialProps } from "./Testimonial";
import { Heading } from "../Typography/Heading";
import { PageSectionContainer } from "../PageSection";
import { Arrow } from "../icons/Arrow";

const oneColumnWidth = "100%";
const twoColumnWidth = "50%";
const threeColumnWidth = "33.333%";
const desktopGridGap = 80;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 16px;

    ${(p) => p.theme.media("sm")} {
        gap: 32px;
    }
`;

const TestimonialListWrapper = styled.div`
    width: calc(100% - 64px);
    overflow: hidden;

    ${(p) => p.theme.media("sm")} {
        width: calc(100% - 96px);
    }
`;

const TestimonialList = styled.ul<{ columns: number; total: number }>`
    display: flex;
    flex-direction: row;
    gap: 0px;

    > li {
        min-width: 100%;
        width: 100%;
    }

    ${(p) => p.theme.media("lg")} {
        gap: 16px;

        > li {
            min-width: calc((100% - 16px) / 2);
            width: calc((100% - 16px) / 2);
        }
    }

    ${(p) => p.theme.media("xl")} {
        gap: 32px;

        > li {
            min-width: calc((100% - 64px) / 3);
            width: calc((100% - 64px) / 3);
        }
    }

    /* ${(p) => p.theme.media("lg")} {
        grid-template-columns: repeat(
            ${(p) => p.total},
            calc((100% - 16px) / 2)
        );
        gap: 16px;
    }

    ${(p) => p.theme.media("xl")} {
        grid-template-columns: repeat(${(p) => p.total}, ${threeColumnWidth});
    } */
`;

const getTestimonialOrder = (
    testimonialIndex: number,
    selectedIndex: number,
    total: number
) => {
    if (testimonialIndex < selectedIndex) {
        return total + testimonialIndex;
    }

    return selectedIndex;
};

const TestimonialListItem = styled.li<{
    index: number;
    selectedIndex: number;
    total: number;
}>`
    /* padding: 0 ${desktopGridGap / 2}px; */
    order: ${(p) => getTestimonialOrder(p.index, p.selectedIndex, p.total)};
`;

// Next/previous arrow button
const ChangePageButton = styled.div`
    align-self: center;
    width: 16px;

    ${(p) => p.theme.media("sm")} {
        width: 32px;
    }

    :disabled {
        opacity: 0;
    }
`;

interface TestimonialsProps {
    testimonials: readonly TestimonialProps[];
}

export const Testimonials = ({ testimonials }: TestimonialsProps) => {
    const [displayedCount, setDisplayedCount] = useState(1);
    const [index, setIndex] = useState(0);

    const theme = useContext(ThemeContext);

    const onLeftClick = () => {
        setIndex((value) => value - displayedCount);
    };

    const onRightClick = () => {
        setIndex((value) => value + displayedCount);
    };

    const isLastIndex = !testimonials[index + displayedCount];

    const isShowButtons = testimonials.length > displayedCount;

    return (
        <Container>
            {isShowButtons && (
                <ChangePageButton
                    aria-label="Previous"
                    role="button"
                    disabled={index === 0}
                    onClick={onLeftClick}
                >
                    <Arrow
                        direction="left"
                        color={theme.color.primary.main}
                        size="100%"
                    />
                </ChangePageButton>
            )}
            <TestimonialListWrapper>
                <TestimonialList
                    columns={displayedCount}
                    total={testimonials.length}
                >
                    {testimonials.map((props, i) => (
                        <TestimonialListItem
                            key={props?.id}
                            index={i}
                            selectedIndex={index}
                            total={testimonials.length}
                        >
                            <Testimonial {...props} />
                        </TestimonialListItem>
                    ))}
                </TestimonialList>
            </TestimonialListWrapper>
            {isShowButtons && (
                <ChangePageButton
                    role="button"
                    aria-label="Next"
                    disabled={isLastIndex}
                    onClick={onRightClick}
                >
                    <Arrow
                        direction="right"
                        color={theme.color.primary.main}
                        size="100%"
                    />
                </ChangePageButton>
            )}
        </Container>
    );
};

const TestimonialsBlockContainer = styled(PageSectionContainer)`
    background: ${(p) => p.theme.color.background.dim};

    && {
        padding-top: 48px;
        padding-bottom: 48px;
    }
`;

export const TestimonialsBlock = ({ testimonials }: TestimonialsProps) => {
    return (
        <TestimonialsBlockContainer>
            <Heading level="h2" levelStyle="h4">
                Here's what our customers say about us
            </Heading>
            <Testimonials testimonials={testimonials} />
        </TestimonialsBlockContainer>
    );
};
