const toArticle = (node) => ({
    date: node.publishedAt,
    description: node.description,
    imageWithMeta: node.featuredImage,
    path: node.slug ? node.slug.current : "",
    title: node.title,
    category: node.category ? node.category.name : "Resource", // Setting a default Category name if the blog post doesn't have a category set.
});

export default toArticle;
