import React from "react";
import styled from "styled-components";
import { Flex } from "../ThemeFlex";
import { Heading } from "../Typography/Heading";

import { gridSquares } from "../../styles/grid";
import Button from "../Button";
import { MarketplaceStats } from "./MarketplaceStats";
import { ProductMarketplaceLink } from "./ProductMarketplaceHeroData";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { Hyperlink } from "../Link/Hyperlink";

interface ProductTitleWithReviewProps {
    icon: IGatsbyImageData;
    title: string;
    subtitle: string;
    appId: string;
    links: ProductMarketplaceLink[];
}

export const ProductTitleWithReview = ({
    icon,
    title,
    subtitle,
    appId,
    links,
}: ProductTitleWithReviewProps) => {
    return (
        <ProductTitleBarContainer flexDirection="row" alignItems="start">
            <Hyperlink href={`https://marketplace.atlassian.com/apps/${appId}`}>
                <AppIconWrapper>
                    <GatsbyImage image={icon} alt={title} />
                </AppIconWrapper>
            </Hyperlink>
            <div>
                <HeadingContainer>
                    <Title level="h1" levelStyle="h3">
                        <Hyperlink
                            href={`https://marketplace.atlassian.com/apps/${appId}`}
                        >
                            {title}
                            {subtitle && <Subtitle>{subtitle}</Subtitle>}
                        </Hyperlink>
                    </Title>
                </HeadingContainer>
                <MarketplaceStats
                    appId={appId}
                    showFortified={true}
                    showReviews={true}
                    showInstalls={true}
                    showNumberOfReviews={false}
                />
                <SpacedFlexRow alignItems="start" width="100%">
                    {links.map((link, index) => {
                        return (
                            <TagButton
                                key={`title-link${index}`}
                                href={link.url}
                            >
                                {link.text}
                            </TagButton>
                        );
                    })}
                </SpacedFlexRow>
            </div>
        </ProductTitleBarContainer>
    );
};

const ProductTitleBarContainer = styled(Flex)`
    gap: 24px;
    flex-wrap: wrap;
    justify-content: center;

    ${(p) => p.theme.media("sm")} {
        flex-wrap: unset;
    }
`;

const HeadingContainer = styled(Flex)`
    flex-wrap: wrap;
    margin-bottom: ${gridSquares(0.25)};
`;

const Title = styled(Heading)`
    margin: 0;
    word-break: break-word;
`;

const Subtitle = styled.span`
    display: block;
    font-size: 0.5em;
    line-height: 1em;
    margin: 0.5em 0 0 0;
`;

const AppIconWrapper = styled.div`
    width: 75px;
    height: 75px;
    flex: 0 0 75px;
`;

const SpacedFlexRow = styled(Flex)`
    flex-wrap: wrap;

    & > * {
        margin-right: 6px;
    }
`;

const TagButton = styled(Button)`
    padding: 4px 12px;
    margin-top: 12px;
    font-weight: ${(p) => p.theme.fontWeight.bold};
    font-size: ${(p) => p.theme.fontSize.pillButton};
    white-space: nowrap;

    color: ${(p) => p.theme.color.pillButton.contrastText};
    background: ${(p) => p.theme.color.pillButton.main};
    text-transform: uppercase;
    border-radius: ${(p) => p.theme.borderRadius.s};
    border: none;

    :hover {
        background: ${(p) => p.theme.color.accentOne.main};
        color: ${(p) => p.theme.color.accentOne.contrastText};
        border: none;
    }
`;
