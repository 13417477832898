import { getSrc } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import { Card } from "../Card";
import { Hyperlink } from "../Link/Hyperlink";

const TestimonialCard = styled(Card)`
    background: ${(p) => p.theme.sectionTheme.backgroundColor};
    color: ${(p) => p.theme.sectionTheme.fontColor};

    height: 100%;
`;

const QuoteContainer = styled.figure`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const QuoteText = styled.blockquote`
    display: flex;
    margin: 0;
    padding: 0;
    font-size: 18px;
    flex-grow: 1;
`;

const QuoteMeta = styled.figcaption`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 32px;

    ${(p) => p.theme.media("sm")} {
        flex-direction: row;
    }
`;

const Portrait = styled.img`
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
`;

const LogoContainer = styled.div`
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: ${(p) => p.theme.color.primary.main};
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Logo = styled.img`
    width: 48px;
    object-fit: cover;
`;

const QuoteAuthor = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 110px;
    font-size: 16px;
    margin-left: 20px;
`;

const Name = styled.p`
    font-weight: ${(p) => p.theme.fontWeight.heavy};
`;

const Company = styled.p``;

export type TestimonialProps = Pick<
    GatsbyTypes.SanityCustomerTestimonial,
    "id" | "name" | "quote" | "company" | "profile" | "logo"
>;

export const Testimonial = ({
    company,
    name,
    quote,
    profile,
    logo,
}: TestimonialProps) => {
    // Prefer company logo, use author profile photo as a backup. New branding
    // uses company logos but many existing testimonials have a profile image.
    const logoImageSrc = getSrc(logo?.asset?.gatsbyImageData);
    const profileImageSrc = getSrc(profile?.img.asset.gatsbyImageData);
    return (
        <Hyperlink
            className={`testimonial-${company}`}
            href={profile?.link || `#testimonial-${company}`}
        >
            <TestimonialCard>
                <QuoteContainer>
                    <QuoteText>{quote}</QuoteText>
                    <QuoteMeta>
                        {logoImageSrc ? (
                            <LogoContainer>
                                <Logo
                                    loading="lazy"
                                    src={logoImageSrc}
                                    alt={name}
                                />
                            </LogoContainer>
                        ) : (
                            profileImageSrc && (
                                <Portrait src={profileImageSrc} alt={name} />
                            )
                        )}
                        <QuoteAuthor>
                            <Name>{name}</Name>
                            {company && <Company>{company}</Company>}
                        </QuoteAuthor>
                    </QuoteMeta>
                </QuoteContainer>
            </TestimonialCard>
        </Hyperlink>
    );
};
