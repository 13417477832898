import React from "react";
import styled, { css } from "styled-components";
import { ProductTitleWithReview } from "./ProductTitleWithReview";
import { ProductBarDemoButtons } from "./ProductBarDemoButtons";
import { ProductBarPricing } from "./ProductBarPricing";
import { ProductBarLinks } from "./ProductBarLinks";
import { ShowWhenGreaterThan, ShowWhenLessThan } from "../Breakpoints";
import { gridSquares } from "../../styles/grid";
import { EABlockContent } from "../BlockContent";
import { BlogPostBodyWrapper } from "../Blog/BlogPostBodyWrapper";
import { IGatsbyImageData } from "gatsby-plugin-image";

interface ProductPageHeroProps {
    icon: IGatsbyImageData;
    title: string;
    subtitle: string;
    appId: string;
    shortTitle: string;
    productPageData: any;
}

export const ProductPageHero = ({
    icon,
    title,
    subtitle,
    appId,
    shortTitle,
    productPageData,
}: ProductPageHeroProps) => {
    return (
        <MarketplaceLayout>
            <PrimaryColumn>
                {/* title bar with integrated review data. */}
                <ProductTitleWithReview
                    icon={icon}
                    title={title}
                    subtitle={subtitle}
                    appId={appId}
                    links={productPageData.tagLinks}
                />

                {/* show call to action at top on small screen */}
                <ShowWhenLessThan screenSize="lg">
                    <ProductBarDemoButtons
                        appId={appId}
                        shortTitle={productPageData.title}
                        demoUrl={productPageData.demoUrl}
                        topButtonUrl={productPageData.topButtonUrl}
                        topButtonLabel={productPageData.topButtonLabel}
                        bottomButtonUrl={productPageData.bottomButtonUrl}
                        bottomButtonLabel={productPageData.bottomButtonLabel}
                    />
                </ShowWhenLessThan>

                {/* main contents of the page, youtube links, links to sandbox */}
                <BlockContentContainer>
                    <EABlockContent blocks={productPageData._rawBody} />
                </BlockContentContainer>
            </PrimaryColumn>
            {/* right hand sidebar */}
            <SideBar>
                {/* on large screens, show calls to action on right */}
                <ShowWhenGreaterThan screenSize="lg">
                    <ProductBarDemoButtons
                        appId={appId}
                        shortTitle={shortTitle}
                        demoUrl={productPageData.demoUrl}
                        topButtonUrl={productPageData.topButtonUrl}
                        topButtonLabel={productPageData.topButtonLabel}
                        bottomButtonUrl={productPageData.bottomButtonUrl}
                        bottomButtonLabel={productPageData.bottomButtonLabel}
                    />
                    <SidebarSectionDivider />
                </ShowWhenGreaterThan>

                {/* pricing details*/}
                <ProductBarPricing appId={appId} />
                <SidebarSectionDivider />
                {/* outgoing links */}
                <ProductBarLinks
                    productBarLinks={productPageData.productBarLinks}
                />
            </SideBar>
        </MarketplaceLayout>
    );
};

const hrStyles = css`
    width: 100%;
    border-top: 1px solid ${(p) => p.theme.color.divider.subtle};
    border-right: none;
    border-bottom: none;
    border-left: none;
`;

const SidebarSectionDivider = styled.hr`
    ${hrStyles}
`;

const MarketplaceLayout = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    padding-top: ${gridSquares(2)};
    padding-left: ${gridSquares(2)};
    padding-right: ${gridSquares(2)};

    padding-bottom: 40px;
    border-bottom: 1px solid ${(p) => p.theme.color.divider.subtle};

    @media (min-width: ${(p) => p.theme.breakpoint.lg}px) {
        flex-direction: row;

        align-items: start;
    }
`;

const PrimaryColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding-right: unset;
    width: 100%;

    border-right: unset;

    /* big screens*/
    @media (min-width: ${(p) => p.theme.breakpoint.lg}px) {
        width: 70%;

        padding-right: ${gridSquares(2.5)};
        border-right: 1px solid ${(p) => p.theme.color.divider.subtle};
    }
`;

const SideBar = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;

    width: 100%;
    max-width: 300px;
    padding-left: unset;

    /* big screens*/
    @media (min-width: ${(p) => p.theme.breakpoint.lg}px) {
        width: 30%;
        padding-left: ${gridSquares(2.5)};
    }

    & > * {
        padding-bottom: ${gridSquares(0.5)};
        margin-bottom: ${gridSquares(1)};
    }
`;

/**
 * Fix EABlockContent which is not behaving well - it needs the
 * blog wrapper to define the styles
 */
const BlockContentContainer = styled(BlogPostBodyWrapper)`
    margin-top: 64px;
    max-width: unset;
`;
