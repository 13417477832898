import React from "react";
import styled from "styled-components";
import { Hyperlink } from "../Link/Hyperlink";
import { MdChevronRight } from "@react-icons/all-files/md/MdChevronRight";
import { Heading } from "../Typography/Heading";
import { GetPricingUrl } from "../../constants/urls";

export const ProductBarPricing = ({ appId }) => {
    return (
        <ProductBarContainer>
            <Heading level="h3" levelStyle="h6">
                Pricing
            </Heading>
            <List>
                <li>Free 30 day trial</li>
                <li>Free forever for up to 10 users on Cloud</li>
                <li>
                    Cloud and Data Center pricing scaled to suit your Jira users
                </li>
            </List>
            <Hyperlink href={GetPricingUrl(appId)}>
                <CalculatorText>
                    Price calculator
                    <MdChevronRight />
                </CalculatorText>
            </Hyperlink>
        </ProductBarContainer>
    );
};

const CalculatorText = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${(p) => p.theme.color.secondaryOne.main};
    margin-left: 12px;
`;

const ProductBarContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1em;
`;

const List = styled.ul`
    margin-left: 12px;
    list-style-type: "- ";

    li {
        margin-bottom: 0.5em;
    }
`;
