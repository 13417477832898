import React from "react";
import styled from "styled-components";
import { Head } from "../Head";
import { PageSectionContainer } from "../PageSection";
import { ProductPageHero } from "./ProductPageHero";
import { Heading } from "../Typography/Heading";
import { ImageWithSideText } from "../ImageWithSideText";

import { TestimonialsBlock } from "../Testimonial/Testimonials";
import { ArticleTiles } from "../Articles/ArticleTiles";
import toArticle from "../../util/toArticle";
import { ContentGroups } from "../../constants/contentGroups";

/**
 * This is the top level component that handles the querying of data and passing to
 * other parts of the UI.
 * @returns
 */
export const ProductPageContents = ({ sanityData, appId }) => {
    // use the slug to find the current product/productPage
    const currentProduct = sanityData.products.find(
        (product) => appId === product?.atlassianProductId
    );

    if (!currentProduct) return <>Page Not Found</>;

    // grab the product page portion.
    const productPage = currentProduct.productPage;

    return (
        <>
            <Head
                contentGroup={ContentGroups["product-aware"]}
                title={currentProduct.productPage.title}
            />{" "}
            {/* TODO: this really should be a separate pageTitle field for SEO */}
            <HeroSectionContainer>
                <ProductPageHero
                    icon={currentProduct.icon.asset.gatsbyImageData}
                    title={productPage.title}
                    subtitle={productPage.subtitle}
                    appId={appId}
                    shortTitle={currentProduct.title}
                    productPageData={productPage}
                />
            </HeroSectionContainer>
            <ExplainerSection explainers={productPage.explainers} />
            <TestimonialsBlock
                testimonials={productPage.featuredTestimonials}
            />
            <BlogSection>
                <Articles
                    articles={productPage.featuredArticles}
                    title={productPage.featuredArticlesTitle}
                />
            </BlogSection>
        </>
    );
};

const HeroSectionContainer = styled(PageSectionContainer)`
    max-width: 1216px;
    padding-top: 80px;
`;

const BlogSection = styled(PageSectionContainer)`
    padding-top: 80px;
    padding-bottom: 80px;
`;

const ExplainerContainer = styled(PageSectionContainer)`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1216px;
    padding-top: 64px;
    padding-bottom: 80px;

    > div {
        display: flex;
        flex-direction: column;
        gap: 80px;
    }
`;

/**
 * The second section below the fold, explaining more details
 * about the product
 */
const ExplainerSection = ({ explainers }) => {
    return (
        <ExplainerContainer>
            {explainers.map((item) => (
                <ImageWithSideText
                    heading={item.heading}
                    description={item.description}
                    graphic={item.graphic}
                    ctaButton={item.ctaButton}
                    imagePosition={item.imagePosition}
                />
            ))}
        </ExplainerContainer>
    );
};

const Articles = ({ articles, title }) => {
    return (
        <>
            <Heading level="h2" levelStyle="h2">
                {title || "Learn More"}
            </Heading>
            <ArticleTiles articles={articles.map(toArticle)} />
        </>
    );
};
