import React from "react";
import styled from "styled-components";
import { Flex } from "../ThemeFlex";

import CloudFortifiedLogo from "../../../content/imgs/atlassian/CloudFortifiedLogo.svg";
import DownloadIconSrc from "../../../content/imgs/icons/download.svg";
import { gridSquares } from "../../styles/grid";

import { RatingOutOfFour } from "../icons/Rating";
import {
    useMarketplaceData,
    getMarketplaceProductReview,
    getAggregateMarketplaceReviews,
} from "../MarketplaceDataContext";
import { Hyperlink } from "../Link/Hyperlink";

export const MarketplaceStats = ({
    appId,
    showFortified,
    showInstalls,
    showReviews,
    showNumberOfReviews,
}) => {
    const marketplaceData = useMarketplaceData();

    const reviewSummary = appId
        ? getMarketplaceProductReview(marketplaceData, appId)
        : getAggregateMarketplaceReviews(marketplaceData);

    if (!reviewSummary) {
        return null;
    }

    return (
        <SubtleText>
            <SpacedFlexRow
                width="100%"
                alignItems="stretch"
                marginTop={gridSquares(1)}
            >
                {showReviews && (
                    <ReviewStars
                        review={reviewSummary.rating}
                        showNumberOfReviews={showNumberOfReviews}
                        numberOfReviews={reviewSummary.numReviews}
                        appId={appId}
                    ></ReviewStars>
                )}
                {showInstalls && (
                    <InstallsIcon numInstalls={reviewSummary.numInstalls} />
                )}
                {showFortified && <CloudFortified />}
            </SpacedFlexRow>
        </SubtleText>
    );
};

const CloudFortified = () => {
    return (
        <Flex>
            <img alt="Cloud Fortified" src={CloudFortifiedLogo} />
            CLOUD FORTIFIED
        </Flex>
    );
};

const InstallsIcon = ({ numInstalls }) => {
    return (
        <Flex flexDirection="row">
            <DownloadIcon alt="Downloads" src={DownloadIconSrc} />
            <span>{numInstalls} installs</span>
        </Flex>
    );
};

const ReviewStars = ({
    review,
    numberOfReviews,
    showNumberOfReviews,
    appId,
}) => {
    return (
        <Hyperlink
            aria-label="Reviews"
            href={`https://marketplace.atlassian.com/apps/${appId}/?tab=reviews`}
        >
            <Flex flexDirection="row">
                <RatingOutOfFour review={review} size="20px" />
                {showNumberOfReviews && (
                    <ReviewPill>{numberOfReviews}</ReviewPill>
                )}
            </Flex>
        </Hyperlink>
    );
};

const SpacedFlexRow = styled(Flex)`
    flex-wrap: wrap;
    & > * {
        margin-right: 6px;
    }
`;

const SubtleText = styled.div`
    color: ${(p) => p.theme.color.background.subtleText};
    font-size: ${(p) => p.theme.fontSize.pillButton};
`;

const ReviewPill = styled.div`
    margin-left: 6px;
    border-radius: ${(p) => p.theme.borderRadius.l};
    padding-right: 6px;
    padding-left: 6px;
    background-color: ${(p) => p.theme.color.background.dim};
`;

const DownloadIcon = styled.img`
    margin-right: 3px;
`;
