import _ from "lodash";
import React, { useMemo } from "react";
import { FaStar } from "@react-icons/all-files/fa/FaStar";
import styled from "styled-components";
import { Flex } from "../ThemeFlex";

// Star colours same as atlassian
const gold = `#FFC400`;
const grey = `#A5ADBA`;

/** Required to be on pages where star ratings are used.
 *  The half star style references this component and must exist for the style to work
 */

const roundToNearestHalf = (rating) => _.round(rating / 0.5) * 0.5;
/** Styled star depending on the rating
 * Using transient prop names to prevent writing out to the dom
 * read more: https://styled-components.com/docs/api#transient-props
 */
const Star = styled(FaStar)<{ $rating: number; $halfStarName: string }>`
    vertical-align: middle;
    fill: ${(p) => {
        if (roundToNearestHalf(p.$rating) >= 1) return gold;

        if (roundToNearestHalf(p.$rating) >= 0.5) {
            return `url(#${p.$halfStarName})`;
        } else {
            return grey;
        }
    }};
`;

export const RatingOutOfFour = ({ review, size }) => {
    return (
        <SpacedFlexRow flexDirection="row" alignItems="center">
            {generateRating(review, size)}
        </SpacedFlexRow>
    );
};

const SpacedFlexRow = styled(Flex)`
    & > * {
        margin-right: 2px;
    }
`;

/**
 * @param rating Rating of a product (out of 4)
 * @param size Size of the star icon
 * @returns An array of styled stars
 * @example generateRating(averageRating, "20")
 */
export const generateRating = (rating: number, size: string | number) => {
    const urlName = useMemo(() => "halfStar" + Math.random(), []);
    const totalRating = [
        // generate the half star linear gradient
        <svg width="0px" height="0px">
            <linearGradient id={urlName}>
                <stop offset="50%" stopColor={gold}></stop>
                <stop offset="50%" stopColor={grey}></stop>
            </linearGradient>
        </svg>,
    ];
    let currentRating = _.round(rating, 2);

    for (let stars = 0; stars < 4; stars++) {
        totalRating.push(
            <Star
                key={stars}
                $halfStarName={urlName}
                $rating={currentRating}
                size={size}
            />
        );
        currentRating--;
    }

    return totalRating;
};
