import React from "react";

import styled from "styled-components";
import { Hyperlink } from "../Link/Hyperlink";
import { Flex } from "../ThemeFlex";
import { MdChevronRight } from "@react-icons/all-files/md/MdChevronRight";

import { Heading } from "../Typography/Heading";
import { GatsbyImage } from "gatsby-plugin-image";

export const ProductBarLinks = ({ productBarLinks }) => {
    return (
        <ProductBarContainer>
            <Heading level="h3" levelStyle="h6">
                Learn more
            </Heading>
            {productBarLinks.map((link, index) => {
                return (
                    <UnderlinedLink key={`link${index}`} href={link.url}>
                        <Flex flexDirection="row" alignItems="center">
                            <LinkIcon>
                                <GatsbyImage
                                    image={link.icon.asset.gatsbyImageData}
                                    alt={link.text}
                                    style={{ width: "22px", height: "22px" }}
                                ></GatsbyImage>
                            </LinkIcon>
                            <span>
                                {link.text} <MdChevronRight />
                            </span>
                        </Flex>
                    </UnderlinedLink>
                );
            })}
        </ProductBarContainer>
    );
};

const LinkIcon = styled.div`
    margin-right: 10px;
`;
const UnderlinedLink = styled(Hyperlink)`
    span {
        font-size: 16px;
        width: 100%;
        display: flex;
        align-items: center;
        white-space: nowrap;
        color: ${(p) => p.theme.color.secondaryOne.main};

        :hover {
            text-decoration: underline;
        }
    }
`;

const ProductBarContainer = styled.div`
    display: flex;

    flex-direction: column;
    & > * {
        margin-top: 10px;
        margin-bottom: 10px;
    }
`;
