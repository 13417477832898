import React from "react";
import styled from "styled-components";
import { gridSquares } from "../../styles/grid";
import Button from "../Button";
import { Heading } from "../Typography/Heading";

import { Flex } from "../ThemeFlex";
import { ShowWhenGreaterThan } from "../Breakpoints";

import { GetTrialUrl } from "../../constants/urls";

export const ProductBarDemoButtons = ({
    appId,
    demoUrl,
    topButtonUrl,
    topButtonLabel,
    bottomButtonUrl,
    bottomButtonLabel,
    shortTitle,
}) => {
    const data = {
        title: `Discover ${shortTitle}`,
        buttonList: [
            {
                title: topButtonLabel,
                href: topButtonUrl,
                buttonVariation: "redBackgroundLightText",
            },
            {
                title: bottomButtonLabel,
                href: bottomButtonUrl,
                buttonVariation: "redBackgroundLightText",
            },
        ].filter((button) => button.title && button.href),
    };

    if (data.buttonList.length === 0) {
        data.buttonList.push({
            title: "View Demo",
            href: demoUrl,
            buttonVariation: "redBackgroundLightText",
        });
    }

    return (
        <ProductBarContainer>
            <Flex flexDirection="column" width="100%" alignItems="center">
                <ButtonContainer>
                    <Heading level="h2" levelStyle="h6">
                        Try {shortTitle}
                    </Heading>
                    <MarketplaceButton
                        variation="darkPurpleBackgroundLightText"
                        aria-label="Find Easy Agile on the Atlassian Marketplace"
                        href={GetTrialUrl(appId)}
                        center={true}
                        size="l"
                    >
                        <AtlassianLogo
                            alt="Find Easy Agile on the Atlassian Marketplace"
                            src="/images/product-page/atlassian-marketplace.svg"
                        />
                    </MarketplaceButton>

                    <Heading level="h2" levelStyle="h6">
                        {data.title}
                    </Heading>

                    {data.buttonList.map((button, index) => {
                        if (!(button.title && button.href)) return null;

                        return (
                            <Button
                                variation={button.buttonVariation}
                                href={button.href}
                                key={`demoButtons${index}`}
                                center={true}
                                size="l"
                                invert={true}
                            >
                                {button.title}
                            </Button>
                        );
                    })}
                </ButtonContainer>
            </Flex>
        </ProductBarContainer>
    );
};

const AtlassianLogo = styled.img`
    width: 100%;
    max-width: 250px;
`;

const ButtonContainer = styled.div`
    display: flex;
    margin-top: 12px;
    margin-bottom: 12px;
    flex-direction: column;
    gap: 12px;

    a {
        width: 100%;
        padding-left: auto;
        padding-right: auto;
        white-space: nowrap;
    }

    svg {
        margin-right: 1em;
        transform: translateY(1px);
    }
`;

const ProductBarContainer = styled.div`
    display: flex;
    padding-bottom: 1em;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    .buttonContainer > {
        margin: ${gridSquares(1)};
    }

    @media (max-width: ${(p) => p.theme.breakpoint.lg}px) {
        display: ${(p) => (p.display ? p.display : "block")};
        border-bottom: 1px solid ${(p) => p.theme.color.divider.subtle};
        padding-left: ${gridSquares(1)};
        padding-right: ${gridSquares(1)};
    }
`;

const MarketplaceButton = styled(Button)`
    padding-left: 16px;
    padding-right: 16px;
    img {
        filter: saturate(0) brightness(5);
        height: 50%;

        transition: filter 140ms ease-in-out;
    }

    :hover {
        img {
            filter: saturate(1);
        }
    }
`;
